<template>
  <div class="bg-color px-3">
    <v-container class="v-container-fluid" fluid>
      <v-row>
        <v-col cols="12" md="12" sm="12" xs="12">
          <div class="content-image-logo">
            <div class="content-logo" style="margin-left: -7px; margin-bottom: 25px; margin-top: 15px;">
              <img src="@/assets/logos/logo-bg-black.svg" alt="" />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="6" xs="12">
          <div class="content-description" style="display: flex; flex-direction: column; align-items: flex-start;">
            <p class="txt-description mon-regular">
              {{ texts.general.sections }}
            </p>
            <v-btn text @click="scrollToSection('Home')" class="txt-description__sub mon-regular">
              {{ texts.general.home }}
            </v-btn>
            <v-btn text @click="scrollToSection('About')" class="txt-description__sub mon-regular">
              {{ texts.general.aboutUs }}
            </v-btn>
            <v-btn text @click="scrollToSection('Buyer')" class="txt-description__sub mon-regular">
              {{ texts.general.buyers }}
            </v-btn>
            <v-btn text @click="scrollToSection('Provider')" class="txt-description__sub mon-regular">
              {{ texts.general.providers }}
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12" md="3" sm="6" xs="12">
          <div class="content-description">
            <p class="txt-description mon-regular">
              {{ texts.general.social }}
            </p>
            <div style="display: flex;">
              <a href="https://instagram.com/proplat.inc?igshid=MzRlODBiNWFlZA==" target="_blank" style="text-decoration: none;">
                <v-icon class="mr-3" style="color: white">mdi-instagram</v-icon>
              </a>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="3" sm="6" xs="12">
          <div class="content-description">
            <p class="txt-description mon-regular">
              {{ texts.general.download }}
            </p>
            <div style="display: flex; flex-direction: column;">
              <img style="width: 170px; height: 59px; margin-top: -6px; margin-right: 10px;" src="@/assets/images/google.png" alt="" />
              <img style="width: 170px; height: 47px" src="@/assets/images/ios.png" alt="" />
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="3" sm="6" xs="12">
          <div class="content-all-rights">
            <language-component></language-component>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <hr class="separator-line" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" sm="6" xs="12" class="text-right">
          <div class="content-poweredby">
            <p class="text-left mon-regular" style="color: white;">
              {{ "© 2023 Proplat" }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="6" sm="6" xs="12" class="text-right">
          <div class="content-poweredby" style="display: flex; justify-content: flex-end;">
            <p class="text-right mon-regular mr-2" style="color: white;">
              {{ texts.general.privacy }}
            </p>
            <p class="text-right mon-regular" style="color: white;">
              {{ "|" }}
            </p>
            <p class="text-right mon-regular ml-2" style="color: white;">
              {{ texts.general.terms }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      //VARIABLES
      texts: "",
    };
  },
  beforeMount: function () {
    this.texts = FILE.landing[this.selectLanguage];
  },
  methods: {
    scrollToSection(sectionId) {
      const targetElement = document.getElementById(sectionId);
      if (targetElement) {
        const offset = 100; // Píxeles de desplazamiento deseado
        const targetPosition = targetElement.offsetTop - offset;
        window.scrollTo({
          top: targetPosition,
          behavior: 'smooth',
        });
      }
    },
    redirectLink: function () {
      let link =
        "https://proplat-public-images.s3.amazonaws.com/PROPLAT-TERMINOS_Y_CONDICIONES.pdf";
      window.open(link, "blank");
    },
    redirectFacebook: function () {
      window.open(
        "https://www.facebook.com/people/Proplat-Proplat/100073376927733/",
        "_blank"
      );
    },
    redirectInstagram: function () {
      window.open("https://www.instagram.com/proplat.inc/", "_blank");
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.landing[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.text-underline:hover {
  cursor: pointer;
  text-decoration: underline;
}

.bg-color {
  width: 100%;
  background: #233040 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-top: 25px;
  padding-bottom: 25px;
}

.content-logo {
  width: 240px;
  height: 32px;
}

.content-logo img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-logo img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.txt-description {
  text-align: left;
  letter-spacing: 0px;
  font-weight: 800;
  color: white;
  opacity: 1;
  font-size: 18px;
  margin-bottom: 10px;
}
.txt-description__sub {
  text-align: left;
  letter-spacing: 0px;
  font-weight: 400;
  color: white;
  opacity: 1;
  font-size: 16px;
  margin-bottom: 0;
}

.txt-link {
  text-align: left;
  letter-spacing: 0px;
  color: #6e7d94;
  opacity: 1;
  font-size: 15px;
  margin-bottom: 0;
  text-transform: initial;
  text-decoration: none;
}

.txt-link:hover {
  color: #0971fb;
  text-decoration: underline;
  cursor: pointer;
}

.txt-social-media {
  letter-spacing: 0px;
  color: #6e7d94;
  opacity: 1;
  font-size: 15px;
  margin-bottom: 0;
}

.separator-line {
  border: 1px solid #bebebe;
}

@media (max-width: 600px) {
  .content-terms,
  .content-all-rights,
  .content-poweredby {
    text-align: center !important;
  }

  .content-social,
  .content-description {
    justify-content: center;
  }

  .content-image-logo {
    display: flex;
    justify-content: center;
  }
}
</style>